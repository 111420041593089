import React from "react";
import { Button, Input, Select, Form, Space } from 'tdesign-react';
import FormItem from 'tdesign-react/es/form/FormItem';
import ReactJson from 'react-json-view';
import { Collapse, TagInput } from 'tdesign-react';

import './monitord.css';

export default class AddHealthContainer extends React.Component {

  formRef = React.createRef();

  SvrTypeList = [
    {label: "redis", value: "redis"},
    {label: "mysql", value: "mysql"},
    {label: "elasticsearch", value: "elasticsearch"},
    {label: "other", value: "other"},
  ]

  state = {
    data: {},
    redis: {}
  }

  constructor(props) {
    super(props);
  }

  AddHealthMember = () => {
    let ref = this.formRef.current;
    let addr = ref.getFieldValue('addr');
    let memberName = ref.getFieldValue('memberName');
    let memberAddr = ref.getFieldValue('memberAddr');
    let svrType = ref.getFieldValue('svrType');
    let processConfig = {
      xixi: "haha",
    }
    let ClickInterval = ref.getFieldValue('clickInterval');

    console.log(addr, memberName, memberAddr, svrType, this.state.redis["addr"], this.state.redis["db"]);
    let body = {
      monitord_name: addr,
      name: memberName,
      process_type: svrType,
      click_interval: ClickInterval,
      process_config: JSON.stringify(processConfig),
    }
  }

  CleanJson = () => {
    this.setState(function (state, props) {
      return {
        data: {},
        svrType: 'other',
      };
    });
  }

  render() {
    const { Panel } = Collapse;
    let JsonViewer = <ReactJson src={this.state.data}/>;
    return (
      <div className="monitord-add-health-container">
        <Form labelWidth={100} colon ref={this.formRef}>
          <h3>新增健康检测任务：</h3>
          <FormItem label="monitord地址" name="addr">
            <Input/>
          </FormItem>
          <FormItem label="服务名称" name="memberName">
            <Input/>
          </FormItem>
          <FormItem label="服务地址" name="memberAddr">
            <Input/>
          </FormItem>
          <FormItem label="拨测间隔(ms)" name="clickInterval">
            <Input/>
          </FormItem>
          <FormItem label="服务类型" name="svrType">
            {
              <Select clearable>
                {this.SvrTypeList.map((item, index) => (
                  <Select.Option value={item.value} label={item.label} key={index} />
                ))}
              </Select>
            }
          </FormItem>
          <br></br>
        </Form>
        <Collapse>
          <Panel header="Redis配置">
            <Input name="addr" onChange={(value) => {
              this.setState(function (state, props) {
                let newState = state;
                newState.redis["addr"] = value;
                return newState;
              });
            }}/>
            <Input name="db" onChange={(value) => {
              this.setState(function (state, props) {
                let newState = state;
                newState.redis["db"] = value;
                return newState;
              });
            }}/>
          </Panel>
        </Collapse>
        <Form>
          <Space>
            <Button onClick={ this.AddHealthMember }>新增</Button>
            <Button onClick={ this.CleanJson }>清除</Button>
          </Space>
        </Form>
        <div>这里将会显示请求的结果</div>
        <br></br>
          {JsonViewer}
        <br></br>
      </div>
    )
  }
}