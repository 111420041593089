import './monitord.css';
import React from "react";
import { Layout } from 'tdesign-react';
import { load } from 'react-cookies';
import GetHealthContainer from './getHealthContainer';
import AddHealthContainer from './addHealthContainer';

export default class Monitord extends React.Component {
  constructor(props) {
    super(props);
    if (load('Admind-Auth-Token') == null) {
      let {
        history
      } = this.props;
      history.push({'pathname': '/login'});
    }
  }

  render() {
    return (
      <div id="monitord">
        <Layout>
          <Layout.Content>
            <div className="monitord-div-container">
              <GetHealthContainer/>
              <AddHealthContainer/>
            </div>
          </Layout.Content>
        </Layout>
      </div>
    )
  }
}
