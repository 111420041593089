import React from "react";
import { Layout } from 'tdesign-react';
import { load } from 'react-cookies';

export default class Configd extends React.Component {
  constructor(props) {
    super(props);
    if (load('Admind-Auth-Token') == null) {
      let {
        history
      } = this.props;
      history.push({'pathname': '/login'});
    }
  }

  render() {
    return (
      <div id="configd">
        <Layout>
          <Layout.Content>
            HAHA-configd
          </Layout.Content>
        </Layout>
      </div>
    )
  }
}
