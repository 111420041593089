import 'tdesign-react/es/style/index.css';
import './App.css';


import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './login/login';
import Configd from './configd/configd';
import Monitord from './monitord/monitord';

import { Layout, Menu } from 'tdesign-react';
import {
  SearchIcon,
  NotificationFilledIcon,
  HomeIcon,
} from 'tdesign-icons-react';

const { Header, Content, Footer } = Layout;
const { HeadMenu, MenuItem } = Menu;

class RouterWrap extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div id="router">
        <Router>
          <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/monitord" component={Monitord} />
            <Route path="/configd" component={Configd} />
            <Route path="/login" component={Login} />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Layout>
          <Header>
            <HeadMenu
              // logo={<img width="136" src="" alt="logo" />}
              operations={
                <div className="t-menu__operations">
                  <SearchIcon className="t-menu__operations-icon" />
                  <NotificationFilledIcon className="t-menu__operations-icon" />
                  <HomeIcon className="t-menu__operations-icon" />
                </div>
              }
              theme='dark'
            >
              <MenuItem
                value='monitord'
                href='/monitord'
              >
                monitord
              </MenuItem>
              <MenuItem
                value='configd'
                href='/configd'
              >
                configd
              </MenuItem>
            </HeadMenu>
          </Header>
          <Content>
            <RouterWrap></RouterWrap>
          </Content>
          <Footer className='admind-index-footer'>
            Copyright @ 2021-2022 AtomEcho. All Rights Reserved
          </Footer>
        </Layout>
      </div>
    )
  }
}