import './login.css';
import React from 'react';
import { Form, Input, Button, MessagePlugin } from 'tdesign-react';
import { DesktopIcon, LockOnIcon } from 'tdesign-icons-react';
import { CallLogin } from '../util/caller';
import { load, save } from 'react-cookies';

import AuthTokenKey from '../util/conststr';

const { FormItem } = Form;

export default class Login extends React.Component {

  loginRef = React.createRef();

  constructor(props) {
    super(props);
    if (load(AuthTokenKey()) != null) {
      window.location.replace('/monitord');
    }
  }

  onSubmit =()=> {
    let ref = this.loginRef.current;
    let username = ref.getFieldValue('username');
    let password = ref.getFieldValue('password');
    if (username == null || password == null) {
      MessagePlugin.error("输入的用户名和密码非法");
      return;
    }
    let body = {
      username: username,
      password: password,
    }
    CallLogin(body)
      .then((res) => {
        save(AuthTokenKey(), res.data['accessToken']);
        window.location.replace('/monitord');
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    return (
      <div className='login-div-container'>
        <div style={{ height: 30 }}></div>
        <div style={{ width: 350 }}>
          <Form 
            ref={this.loginRef}
            statusIcon={true}
            colon={true} 
            labelWidth={0}>
            <FormItem name="username">
              <Input clearable={true} prefixIcon={<DesktopIcon />} placeholder="请输入账户名" />
            </FormItem>
            <FormItem name="password">
              <Input type="password" prefixIcon={<LockOnIcon />} clearable={true} placeholder="请输入密码" />
            </FormItem>
            <FormItem>
              <Button theme="primary" onClick={this.onSubmit} block>
                登录
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}
