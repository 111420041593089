import axios from 'axios';
import { load } from 'react-cookies';
import AuthTokenKey from './conststr';

const Prev = ""

export const CallLogin = (body) => axios.post(Prev + 'admindApi/Login', body);

export const GetMonitorMember = (body) => axios.post(Prev + 'admindApi/GetMonitorMember', body, {
  headers: {
    Authorization: load(AuthTokenKey())
  }
});
