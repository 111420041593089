import React from "react";

import { Button, Input, Checkbox, Form, Space } from 'tdesign-react';

import FormItem from 'tdesign-react/es/form/FormItem';
import { GetMonitorMember } from '../util/caller';
import ReactJson from 'react-json-view';

export default class GetHealthContainer extends React.Component {

  getHealthForm = React.createRef();
  
  state = {
    memberName: null,
    addr: null,
    isSelectAll: false,
    healthResult: "",
    data: {}
  }
  
  constructor(props) {
    super(props);
  }

  getMembersInfoAsync = () => {
    let ref = this.getHealthForm.current;
    let monitordName = ref.getFieldValue('monitordName'); 
    let memberName = ref.getFieldValue("memberName");
    let isGetAll = ref.getFieldValue("isGetAll") != '';
    let query_json = {}
    if (monitordName != null) query_json['monitord_name'] = monitordName;
    if (memberName != null) query_json['member_name'] = memberName;
    query_json['all'] = isGetAll;
    console.log(query_json);
    GetMonitorMember(query_json)
      .then((res) => {
        console.log(res);
        this.setState(function (state, props) {
          return {
            data: res.data
          }
        });
      })
      .catch((e) => {
        console.error(e);
        let res = {err: 'error'}
        this.setState(function (state, props) {
          return {
            data: res
          };
        });
      });
  }

  CleanJson = () => {
    this.setState(function (state, props) {
      return {
        data: {}
      };
    });
  }

  render() {
    let JsonViewer = <ReactJson src={this.state.data}/>;
    return (
      <div className='monitord-get-health-container'>
        <Form labelWidth={100} colon ref={this.getHealthForm}>
          <h3>获取健康检测任务：</h3>
          <FormItem label="monitord名称" name="monitordName">
            <Input/>
          </FormItem>
          <FormItem label="服务名称" name="memberName">
            <Input/>
          </FormItem>
          <FormItem label="是否获取全部" name="isGetAll">
            <Checkbox.Group>
              <Checkbox value={'getAll'}>是否全选</Checkbox>
            </Checkbox.Group>
          </FormItem>
          <br></br>
          <Space>
            <Button onClick={ this.getMembersInfoAsync }>获取</Button>
            <Button onClick={ this.CleanJson }>清除</Button>
          </Space>
        </Form>
        <div>这里将会显示请求的结果</div>
        <br></br>
          {JsonViewer}
        <br></br>
      </div>
    );
  }
}